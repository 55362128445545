<template>
  <div class="container content-p">
    <section class="report-add-wrapper">
      <b-row class="report-add">
        <!-- Col: Left (Report Container) -->
        <b-col cols="12" xl="9" md="8">
          <b-form @submit.prevent>
            <b-card no-body class="report-preview-card">
              <!-- Report Client & Payment Details -->
              <b-card-body class="report-padding">
                <h6 class="mb-1">Student Information</h6>
                <b-row class="report-spacing">
                  <!-- Col: Student Name -->
                  <b-col cols="12" xl="4" class="mb-lg-1">
                    <label>Student Name</label>
                    <b-form-input id="report-data-sales-person" v-model="reportData.name" placeholder="John Doe" />
                  </b-col>

                  <!-- Col: Student Email -->
                  <b-col cols="12" xl="4" class="mb-lg-1">
                    <label>Student Email</label>
                    <b-form-input id="report-data-sales-person" v-model="reportData.email" type="email" placeholder="jdoe@gmail.com" />
                  </b-col>

                  <!-- Col: Pathway Name -->
                  <b-col cols="12" xl="4" class="mb-lg-1">
                    <label>Program Name</label>
                    <b-form-input id="report-data-sales-person" v-model="reportData.programName" placeholder="CompTIA Security+" />
                  </b-col>

                  <!-- Col: Last Date of Attendance -->
                  <b-col cols="12" xl="4" class="mb-lg-1">
                    <label>Program Start</label>
                    <b-form-datepicker id="report-data-program-start" v-model="reportData.programStart" />
                  </b-col>

                  <!-- Col: Last Date of Attendance -->
                  <b-col cols="12" xl="4" class="mb-lg-1">
                    <label>Program End</label>
                    <b-form-datepicker id="report-data-program-end" v-model="reportData.programEnd" />
                  </b-col>

                  <!-- Col: Student Status -->
                  <b-col cols="12" xl="4" class="mb-lg-1">
                    <label>Enrollment Status</label>
                    <b-form-select v-model="reportData.status" :options="studentStatusOptions" />
                  </b-col>

                  <b-col cols="12" xl="12" class="mb-lg-1">
                    <label>Additional Comments</label>
                    <b-form-input
                      id="report-data-sales-person"
                      v-model="reportData.comments"
                      placeholder="Terminated due to poor attendance."
                    />
                  </b-col>
                </b-row>

                <h6 class="mb-1">Payment Plan</h6>

                <b-row class="report-spacing">
                  <!-- Col: Charges - Tuition -->
                  <b-col cols="12" xl="4" class="mb-lg-1">
                    <label>Plan Amount</label>

                    <!-- append and pepend -->
                    <b-input-group prepend="$">
                      <cleave
                        v-model="reportData.paymentPlan.amount"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="0"
                      />
                    </b-input-group>
                  </b-col>

                  <!-- Col: Charges - Books -->
                  <b-col v-if="false" cols="12" xl="4" class="mb-lg-1">
                    <label>Total Paid</label>

                    <!-- append and pepend -->
                    <b-input-group prepend="$">
                      <cleave
                        v-model="reportData.paymentPlan.paid"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="0"
                      />
                    </b-input-group>
                  </b-col>

                  <!-- Col: Charges - Exam -->
                  <b-col v-if="false" cols="12" xl="4" class="mb-lg-1">
                    <label>Balance</label>
                    <!-- append and pepend -->
                    <b-input-group prepend="$">
                      <cleave
                        v-model="reportData.paymentPlan.balance"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="0"
                      />
                    </b-input-group>
                  </b-col>
                </b-row>

                <h6 class="mb-1">Report Charges</h6>

                <b-row class="report-spacing">
                  <!-- Col: Charges - Tuition -->
                  <b-col cols="12" xl="3" class="mb-lg-1">
                    <label>Tuition</label>

                    <!-- append and pepend -->
                    <b-input-group prepend="$">
                      <cleave
                        v-model="reportData.charges.tuition"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="0"
                      />
                    </b-input-group>
                  </b-col>

                  <!-- Col: Charges - Books -->
                  <b-col cols="12" xl="3" class="mb-lg-1">
                    <label>Books</label>

                    <!-- append and pepend -->
                    <b-input-group prepend="$">
                      <cleave
                        v-model="reportData.charges.books"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="0"
                      />
                    </b-input-group>
                  </b-col>

                  <!-- Col: Charges - Exam -->
                  <b-col cols="12" xl="3" class="mb-lg-1">
                    <label>Exam</label>
                    <!-- append and pepend -->
                    <b-input-group prepend="$">
                      <cleave
                        v-model="reportData.charges.exam"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="0"
                      />
                    </b-input-group>
                  </b-col>

                  <!-- Col: Charges - Registration -->
                  <b-col cols="12" xl="3" class="mb-lg-1">
                    <label>Registration</label>

                    <!-- append and pepend -->
                    <b-input-group prepend="$">
                      <cleave
                        v-model="reportData.charges.registration"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="0"
                      />
                    </b-input-group>
                  </b-col>
                </b-row>

                <h6 class="mb-1 mt-3">Payment Record</h6>

                <div ref="formPmnt" class="repeater-form" :style="{ height: trHeight['pmnt'] || 'auto' }">
                  <b-row v-for="(item, index) in reportData.payments" :key="index" ref="rowPmnt" class="pb-2">
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="2"> Date </b-col>
                          <b-col cols="12" lg="4"> Description </b-col>
                          <b-col cols="12" lg="2"> Debit </b-col>
                          <b-col cols="12" lg="2"> Credit </b-col>
                          <b-col cols="12" lg="2"> Paid? </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Date</label>
                            <b-form-datepicker
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                              :id="`report-data-payment-${index}-date`"
                              v-model="item.date"
                            />
                          </b-col>
                          <b-col cols="12" lg="4">
                            <label class="d-inline d-lg-none">Description</label>
                            <b-form-input v-model="item.description" />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Debit</label>
                            <b-input-group prepend="$">
                              <cleave v-model="item.debit" class="form-control" :raw="true" :options="options.number" placeholder="0" />
                            </b-input-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Credit</label>
                            <b-input-group prepend="$">
                              <cleave v-model="item.credit" class="form-control" :raw="true" :options="options.number" placeholder="0" />
                            </b-input-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Paid?</label>
                            <b-form-checkbox v-model="item.paid" switch> Has Paid? </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removePaymentItem(index)" />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addNewPaymentInItemForm">
                  Add Item
                </b-button>
              </b-card-body>
            </b-card>
          </b-form>
        </b-col>

        <!-- Right Col: Card -->
        <b-col cols="12" md="4" xl="3" class="report-actions mt-md-0 mt-2">
          <!-- Action Buttons -->
          <b-card>
            <!-- Button: Print -->
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="initiateReportEdit">
              Save Changes
            </b-button>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
              :to="{ name: 'apps-report-preview', params: { id: this.reportId } }"
            >
              &leftarrow; Return
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <report-sidebar-add-new-customer />
    </section>
  </div>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue';
import { ref, onUnmounted } from '@vue/composition-api';
import { dynamicHeightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormDatepicker,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import reportStoreModule from '../reportStoreModule';
import ReportSidebarAddNewCustomer from '../ReportSidebarAddNewCustomer.vue';
import router from '@/router';
import FormDatepicker from '@/views/forms/form-element/form-datepicker/FormDatepicker';
import FormDatepickerBasic from '@/views/forms/form-element/form-datepicker/FormDatepickerBasic';

import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    Cleave,
    FormDatepickerBasic,
    FormDatepicker,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BFormSelect,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    ReportSidebarAddNewCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [dynamicHeightTransition],
  mounted() {
    this.addNewPaymentInItemForm();
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeightPayment);
  },
  methods: {
    addNewPaymentInItemForm() {
      this.reportData.payments.push(JSON.parse(JSON.stringify(this.itemFormBlankPayment)));
      this.initTrHeightPayment();
    },
    removePaymentItem(index) {
      this.reportData.payments.splice(index, 1);
      this.trTrimHeight('pmnt', this.$refs.rowPmnt[0].offsetHeight);
    },
    initTrHeightPayment() {
      this.trSetHeight('pmnt', null);
      this.$nextTick(() => {
        this.trSetHeight('pmnt', this.$refs.formPmnt.scrollHeight);
      });
    },

    initiateReportEdit() {
      console.log(this.reportData);

      // Remove fields that could cause issues and are automatically calculated
      delete this.reportData.balance;

      // Remove fields that could cause issues and are automatically calculated
      delete this.reportData.balance;

      this.$http
        .patch(`v1/reports/${this.reportId}`, { ...this.reportData, student: undefined })
        .then((response) => {
          if (response.status === 200) {
            const { id } = response.data;

            this.$toast({
              component: ToastificationContent,

              props: {
                title: `Report Updated`,
                icon: 'ArchiveIcon',
                variant: 'success',
                text: `Your report has successfully been modified!`,
              },
            });

            this.$router.push({ name: 'apps-report-preview', params: { id } });
          }

          console.log(response);
        })
        .catch((e) => {
          console.error(e);
        });

      console.log(this.reportData);
    },
  },
  data: function () {
    return {
      reportId: this.$router.currentRoute.params.id,

      reportData: {
        charges: {},
        paymentPlan: {},
        payments: [],
        status: 'graduated',
      },
      paymentDetails: {},
      studentStatusOptions: [
        { value: 'graduated', text: 'Graduated' },
        { value: 'terminated', text: 'Terminated' },
        { value: 'withdrew', text: 'Withdrew' },
      ],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    };
  },
  async created() {
    window.addEventListener('resize', this.initTrHeightPayment);

    this.$http
      .get(`v1/reports/${this.reportId}`)
      .then((response) => {
        if (response.status === 200) {
          let d = response.data;
          delete d.createdAt;
          delete d.updatedAt;
          delete d.id;

          this.reportData = d;

          console.log(this.reportData);
          for (let i = 0; i < this.reportData.payments.length; i++) {
            this.initTrHeightPayment();
          }
        }
      })
      .catch((error) => {
        this.$toast({
          component: ToastificationContent,

          props: {
            title: 'Error fetching report',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'An unknown error has occurred',
          },
        });
      });
  },
  setup() {
    const itemFormBlankPayment = {
      date: null,
      description: '',
      credit: 0,
      debit: 0,
      paid: false,
    };

    return {
      // reportData,
      clients: [],
      itemFormBlankPayment,
    };
  },
};
</script>

<style lang="scss">
/*
.template-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}*/
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-report.scss';
@import '~@core/scss/base/components/theme/variables-dark';
@import '~@core/scss/base/components/theme/variables-blue';
@import '~@core/scss/base/components/theme/variables-midnight';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-body-bg;
    }
  }
}
</style>
